import contentDisposition from 'content-disposition';

/**
 * Parses the UTF-8 file name from the Content-Disposition header
 *
 * @param headers response headers
 * @returns the parsed UTF-8 file name or empty string
 */
export const parseFileName = (headers: Headers): string => {
  const dispositionHeader = headers.get('content-disposition');

  if (!dispositionHeader) return '';

  try {
    const parsed = contentDisposition.parse(dispositionHeader);

    return parsed.parameters.filename || '';
  } catch (e) {
    if (e instanceof TypeError) {
      return '';
    }

    throw e;
  }
};
