import type {Message} from './types';

interface Locale {
  required?: string;
  string?: string;
  length?: Message<{length: number}>;
  min?: Message<{min: number}>;
  max?: Message<{max: number}>;
  email?: string;
  subdomain?: string;
  url?: string;
  hexCode?: string;
  tel?: string;
  tin?: string;
  postalCode?: string;
  secureUrl?: string;
  domain?: string;
  emailDomain?: string;
  publicDomain?: string;
  dateOfBirth?: string;

  number?: string;
  lessThan?: Message<{lessThan: number}>;
  moreThan?: Message<{moreThan: number}>;
  positive?: string;
  negative?: string;
  integer?: string;

  boolean?: string;
  isTrue?: string;
  isFalse?: string;

  attached?: string;
  fileSize?: string;
}

export const locale: Locale = {
  required: '${label} is a required field',
  string: '${label} must be a valid string',
  length: '${label} must be exactly ${length} characters',
  min: '${label} must be at least ${min} characters',
  max: '${label} must be at most ${max} characters',
  email: '${label} must be a valid email',
  subdomain: '${label} must be a valid subdomain',
  url: '${label} must be a valid URL',
  secureUrl:
    'The URL entered is invalid, please update it. (e.g. https://www.example.com)',
  hexCode: '${label} must be a valid Hex code',
  tel: '${label} must be a valid phone number',
  tin: '${label} must be a valid TIN',
  postalCode: '${label} must be a valid zip/postal code',
  domain: '${label} must be a valid domain',
  emailDomain: '${label} must end with ${emailDomain} domain.',
  publicDomain:
    'The domain you entered cannot be used. Please input your company domain.',
  dateOfBirth: 'Must be 18 years of age or older',

  number: '${label} must be a valid number',
  lessThan: '${label} must be less than ${lessThan}',
  moreThan: '${label} must be more than ${moreThan}',
  positive: '${label} must be a positive number',
  negative: '${label} must be a negative number',
  integer: '${label} must be an integer',

  boolean: '${label} must be a boolean value',
  isTrue: '${label} must be true',
  isFalse: '${label} must be false',

  attached: '${label} must be attached',
  fileSize: 'File size of ${label} must be less than ${fileSize} bytes',
};

export default Object.assign(Object.create(null), locale);
