import * as yup from 'yup';
import {rUrlHttpNotrequired} from 'Utility/validations/validators/string';

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateSubdomain = (subdomain: string): boolean => {
  const reservedName = [
    'www',
    'ftp',
    'mail',
    'pop',
    'smtp',
    'admin',
    'ssl',
    'sftp',
    'test',
    'staging',
  ];
  const re = /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])$/;
  return re.test(subdomain) && !reservedName.includes(subdomain);
};

export const validateNumber = (number: string): boolean => {
  const regex = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/;
  return regex.test(number);
};

export const validateURL = (url: string): boolean => {
  const regex = /^[^\s]*\w+(\.\w+)*(:[0-9]+)?\/?$/;
  return regex.test(url);
};

export const urlValidation = yup.string().matches(rUrlHttpNotrequired, {
  message: 'Invalid URL',
  excludeEmptyString: true,
});
