export enum ApiTarget {
  V1,
  Internal,
  EmbedV1,
  Controller,
}

export interface RequestOptions {
  anonymous?: boolean;
}

export type Pagination = {
  page?: number;
  perPage?: number;
  total?: number;
  totalPages?: number;
};
