import type {Params, ValidatorError, CommonInput} from './types';
import {locale} from './locale';
import {camelToWord, capitalizeFirstLetter} from './utils';

const rLabelString = /\$\{\s*(\w+)\s*\}/g;

const formatErrorMessage = (
  validator: string,
  field: string,
  params?: Params
): string => {
  const rParams = new RegExp(`\\$\\{${validator}\\}`, 'g');

  const message = locale[validator]
    .replace(rParams, params)
    .replace(rLabelString, camelToWord(field));

  return capitalizeFirstLetter(message);
};

const handleResult = (
  validator: string,
  field: string,
  invalid: boolean,
  params?: Params
): ValidatorError => {
  const error: ValidatorError = {};

  if (invalid) {
    error[validator] = {
      error: true,
      message: formatErrorMessage(validator, field, params),
    };
  } else {
    error[validator] = {error: false};
  }

  return error;
};

export function hasErrors(validatorErrors: Array<ValidatorError>): boolean {
  return validatorErrors
    .map(v =>
      Object.values(v)
        .map(o => o.error)
        .some(Boolean)
    )
    .some(Boolean);
}

export function fullErrorMessage(
  validatorErrors: Array<ValidatorError>
): string {
  return validatorErrors
    .map(v => Object.values(v).map(o => o.message))
    .filter(f => typeof f[0] === 'string')
    .join('. ');
}

export default function createError(
  value: CommonInput,
  field: string,
  validator: string,
  validateFunction: Function,
  params?: Params
): ValidatorError {
  return handleResult(validator, field, validateFunction(value), params);
}
