export const splitName = (name: string): Array<string> | undefined => {
  if (!name) return ['', ''];
  const nameArray = (name || '')
    .split(' ')
    .map(str => str.trim())
    .filter(str => str !== '');
  const firstName = nameArray[0];
  const lastName = nameArray.slice(1).join(' ');
  return [firstName, lastName];
};
