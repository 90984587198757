import {validUS, validCA, validUK} from 'Utility/commons/countries/codes';

/**
 * Given a string `value` and a `countryCode` determines if `value`
 * matches the TIN format required for the `countryCode`
 *
 * A blank string for `countryCode` will validate the value against US TIN formats
 *
 * @param value tin value to validate
 * @param countryCode country code to determine which format to apply
 * @returns true if value matches the format for the provided countryCode
 */
export const validateTIN = (value: string, countryCode: string): boolean => {
  let rTin: RegExp;

  if (validUS(countryCode)) {
    rTin = /^(\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/; // SSN | EIN
  } else if (validCA(countryCode)) {
    rTin = /^(\d{3}-\d{3}-\d{3}|\d{9})$/; // SIN | EIN
  } else if (validUK(countryCode)) {
    rTin = /^([a-zA-Z]{2}(-)\d{6}(-)[a-zA-Z]{1}|[a-zA-Z0-9]*)$/; // NATIONAL ID | EIN
  } else {
    rTin = /^([a-zA-Z0-9]*)$/; // Other
  }

  return rTin.test(value);
};
