import {writable, get} from 'svelte/store';
import {isEmbedded} from 'Store/embedded/embeddedFlowStore';
import {getEnvironmentVariable} from 'Utility/document';
import {sveltekitContext} from 'Store/sveltekit/store';

export interface AccessToken {
  access_token: string;
  owner_id: number;
  created_at: number;
  expires_in?: number;
}

export type PublicToken = {
  access_token: string;
};

const url = '/auth/token/issue';
const defaultTokenExpirationInHours = 1;

const storedAccessToken = JSON.parse(
  localStorage.getItem('accessToken')
) as AccessToken;

const accessToken = writable(storedAccessToken);
accessToken.subscribe(value => {
  localStorage.setItem('accessToken', JSON.stringify(value));
});

const isValid = (token: AccessToken) =>
  get(isEmbedded) || get(sveltekitContext)
    ? true
    : token.owner_id === Number(getEnvironmentVariable('current_user_id'));

const isExpired = (token: AccessToken) => {
  if (!token) {
    return true;
  }

  const creationDate = new Date(token.created_at * 1000);

  const expiryDate = new Date(creationDate);
  if (!token.expires_in) {
    expiryDate.setHours(expiryDate.getHours() + defaultTokenExpirationInHours);
  } else {
    expiryDate.setSeconds(expiryDate.getSeconds() + token.expires_in);
  }

  const currentDate = new Date(Date.now());

  return currentDate > expiryDate;
};

const getAccessToken = async (): Promise<AccessToken> => {
  const currentAccessToken = get(accessToken);

  if (
    currentAccessToken &&
    !isExpired(currentAccessToken) &&
    isValid(currentAccessToken)
  ) {
    return currentAccessToken;
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const remoteAccessToken = (await response.json()) as AccessToken;

  accessToken.set(remoteAccessToken);

  return remoteAccessToken;
};

const getCurrentAccessToken = (): AccessToken => {
  return get(accessToken);
};

export const getEmbeddedToken = async (): Promise<AccessToken> => {
  const response = await fetch('/auth/token/issue_embedded');
  const remoteAccessToken = (await response.json()) as AccessToken;

  accessToken.set(remoteAccessToken);

  return remoteAccessToken;
};

export const bindAccessToken = (externalToken: AccessToken) => {
  accessToken.set(externalToken);
};

export const isAccessTokenActive = (): boolean => {
  const currentAccessToken = get(accessToken);
  return !isExpired(currentAccessToken) && isValid(currentAccessToken);
};

const create = () => {
  return {
    getAccessToken,
    bindAccessToken,
    getCurrentAccessToken,
    isAccessTokenActive,
  };
};

export default create();
