import {isValidPhoneNumber} from 'libphonenumber-js/max';
import {validateLegalAge} from 'Utility/commons/validateLegalAge';
import createError from 'Utility/validations/createError';
import type {Params, ValidatorError} from 'Utility/validations/types';
import type {GonObject} from 'Types/gon';
import {validateTIN} from 'Utility/commons/validateTIN';
import {dateIsEmpty} from 'Utility/commons/dateField';
import {getEnvironmentVariable} from 'Utility/document';

const RESERVED_SUBDOMAINS = [
  'www',
  'ftp',
  'mail',
  'pop',
  'smtp',
  'admin',
  'ssl',
  'sftp',
  'test',
  'staging',
];

const rEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const rSubdomain = /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])$/;
const rUrl =
  /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;
export const rUrlHttpNotrequired =
  /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;
const rSecureUrl =
  /^((https):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;
const rHexCode = /^#(?:[0-9a-fA-F]{6})$/;
const rPostalCode = /^[a-zA-Z0-9][a-zA-Z0-9\-\s]{0,10}[a-zA-Z0-9]$/;
const rDomain =
  /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;

export function stringValidators(value: string, field: string, params: Params) {
  return {
    string: () => string(value, field),
    length: () => length(value, field, parseInt(params + '')),
    min: () => min(value, field, parseInt(params + '')),
    max: () => max(value, field, parseInt(params + '')),
    email: () => email(value, field),
    subdomain: () => subdomain(value, field),
    url: () => url(value, field),
    secureUrl: () => secureUrl(value, field),
    hexCode: () => hexCode(value, field),
    tel: () => tel(value, field),
    tin: () => tin(value, field, params + ''),
    postalCode: () => postalCode(value, field),
    domain: () => domain(value, field),
    emailDomain: () => emailDomain(value, field, params + ''),
    publicDomain: () => publicDomain(value, field),
    requiredDOB: () => requiredDOB(value, field),
    legalAge: () => legalAge(value, field),
  };
}

const string = (value: string, field: string) => {
  return createError(
    value,
    field,
    'string',
    (value: string) => typeof value !== 'string'
  );
};

const length = (value: string, field: string, params: number) => {
  return createError(
    value,
    field,
    'length',
    (value: string) => !isNaN(params) && value.length === params,
    params
  );
};

const min = (value: string, field: string, params: number) => {
  return createError(
    value,
    field,
    'min',
    (value: string) => !isNaN(params) && value.length < params,
    params
  );
};

const max = (value: string, field: string, params: number) => {
  return createError(
    value,
    field,
    'max',
    (value: string) => !isNaN(params) && value.length >= params,
    params
  );
};

const email = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'email',
    (value: string) => !rEmail.test(value)
  );
};

const subdomain = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'subdomain',
    (value: string) =>
      !rSubdomain.test(value) || RESERVED_SUBDOMAINS.includes(value)
  );
};

const postalCode = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'postalCode',
    (value: string) => !rPostalCode.test(value)
  );
};

const url = (value: string, field: string): ValidatorError => {
  return createError(value, field, 'url', (value: string) => !rUrl.test(value));
};

const secureUrl = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'secureUrl',
    (value: string) => !rSecureUrl.test(value)
  );
};

const hexCode = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'hexCode',
    (value: string) => !rHexCode.test(value)
  );
};

const tel = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'tel',
    (value: string) => !isValidPhoneNumber(value)
  );
};

const tin = (value: string, field: string, params: string): ValidatorError => {
  return createError(
    value,
    field,
    'tin',
    (value: string) => !validateTIN(value, params)
  );
};

const domain = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'domain',
    (value: string) => !rDomain.test(value)
  );
};

const emailDomain = (
  value: string,
  field: string,
  params: string
): ValidatorError => {
  return createError(
    value,
    field,
    'emailDomain',
    (value: string) =>
      !params
        .split(',')
        .some(domain => value.match(new RegExp(`\\b${domain}\\b$`))),
    params
  );
};

const publicDomain = (value: string, field: string): ValidatorError => {
  return createError(value, field, 'publicDomain', (value: string) =>
    getEnvironmentVariable('public_domains')?.split(',').includes(value)
  );
};

const legalAge = (value: string, field: string): ValidatorError => {
  return createError(
    value,
    field,
    'dateOfBirth',
    (value: string) => !validateLegalAge(value)
  );
};

const requiredDOB = (value: string, field: string): ValidatorError => {
  return createError(value, field, 'required', (value: string) =>
    dateIsEmpty(value)
  );
};
