const rCamelCase = /([a-z0-9])([A-Z])/g;

export const capitalizeFirstLetter = (field: string): string => {
  return field.charAt(0).toUpperCase() + field.slice(1);
};

export const camelToWord = (field: string): string => {
  return field.replace(rCamelCase, '$1 $2').toLowerCase();
};

export const customValidationString = (validationString: string): string => {
  return validationString?.substring(
    validationString.lastIndexOf('[') + 1,
    validationString.lastIndexOf(']')
  );
};
