// US, Puerto Rico, US Virgin Islands
export const usCodes = Object.freeze(['US', 'PR', 'VI']);

export const caCode = 'CA';

export const ukCode = 'GB';

// Countries blocked for intl investors Russian Federation, Belarus, Syria, Cuba, Iran, North Korea
export const blockedIntlCodes = Object.freeze([
  'RU',
  'BY',
  'SY',
  'CU',
  'IR',
  'KP',
]);

/**
 * Use this if you want to preserve a US behaviour as default when some user input is empty, most likely not appropriate for a validation
 */

export const validUS = (countryCode: string): boolean => {
  return usCodes.includes(countryCode);
};

export const validCA = (countryCode: string): boolean => {
  return caCode === countryCode;
};

export const validUK = (countryCode: string): boolean => {
  return ukCode === countryCode;
};

export const blockedInternational = (countryCode: string): boolean => {
  return blockedIntlCodes.includes(countryCode);
};

export const validInternational = (countryCode: string): boolean => {
  return (
    countryCode?.length === 2 &&
    !validUS(countryCode) &&
    !validCA(countryCode) &&
    !blockedInternational(countryCode)
  );
};

export const validCountry = (countryCode: string): boolean => {
  return (
    validUS(countryCode) ||
    validUK(countryCode) ||
    validCA(countryCode) ||
    validInternational(countryCode)
  );
};
