import {get} from 'svelte/store';
import {EmbeddedFlowState, viewState, hosted} from 'Store/InvestorFlowStore';
import {embeddedWebComponent} from 'Store/embedded/embeddedFlowStore';

export const transitionToAuthentication = () => {
  if (get(embeddedWebComponent)) {
    viewState.set(EmbeddedFlowState.AUTHENTICATION);
  } else {
    viewState.set(EmbeddedFlowState.ACCESS_LINK);
  }
};

export const transitionToApplication = () => {
  if (get(hosted)) {
    transitionToAuthentication();
  } else {
    viewState.set(EmbeddedFlowState.QUESTION_FLOW);
  }
};
